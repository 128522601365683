// src/helpers/useDynamicReducer.js
import { useState, useEffect } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { addDynamicReducer } from '@services/store/dynamicReducers';

export const useDynamicReducer = (storeName, createSlice, parentStoreName) => {
    const store = useStore();
    const dispatch = useDispatch();
    const [slice, setSlice] = useState(null);

    useEffect(() => {
        if (storeName) {
            const createdSlice = createSlice(storeName, parentStoreName);
            setSlice(createdSlice);
            addDynamicReducer(store, storeName, createdSlice.reducer);
            const actions = createdSlice.actions;
            dispatch(
                actions.setStateProperty({ key: 'storeName', value: storeName })
            );
            if (parentStoreName) {
                dispatch(
                    actions.setStateProperty({
                        key: 'parent',
                        value: parentStoreName,
                    })
                );
            }
        }
    }, [storeName, parentStoreName, dispatch, store]);

    return slice;
};
