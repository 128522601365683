//src\index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import Store from '@services/store';
import { MantineProvider } from '@mantine/core';
import App from '@components/app';
import { Notifications, showNotification } from '@mantine/notifications';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <Provider store={Store}>
        <MantineProvider withGlobalStyles withNormalizeCSS>
            <React.StrictMode>
                <Notifications
                    style={{
                        position: 'absolute',
                        right: '10px',
                        bottom: '10px',
                        overflow: 'hidden',
                    }}
                />
                <App />
            </React.StrictMode>
        </MantineProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
