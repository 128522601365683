import React from 'react';
import { useForm } from '@mantine/form';
import { TextInput, Button, Group, Checkbox, Box } from '@mantine/core';

function FeedbackForm() {
    const form = useForm({
        initialValues: {
            name: '',
            phone: '',
            callback: false,
        },

        validate: {
            name: (value) =>
                value.length < 2
                    ? 'Имя должно содержать минимум 2 символа'
                    : null,
            phone: (value) =>
                /^(\+?\d{1,4}|\d{1,4})?\d{7,10}$/.test(value)
                    ? null
                    : 'Некорректный номер телефона',
        },
    });

    const handleSubmit = (values) => {
        console.log(values);
        // Здесь можно добавить логику для отправки данных
    };

    return (
        <Box sx={{ maxWidth: 400 }} mx="auto">
            <form onSubmit={form.onSubmit(handleSubmit)}>
                <TextInput
                    placeholder="Введите ваше имя"
                    className={'textinput'}
                    {...form.getInputProps('name')}
                />

                <TextInput
                    className={'textinput'}
                    placeholder="Введите ваш номер телефона"
                    {...form.getInputProps('phone')}
                />

                <Checkbox
                    mt="md"
                    label="Подтверждаю согласие на обработку персональных данных"
                    {...form.getInputProps('callback', { type: 'checkbox' })}
                />

                <Group position="right" mt="md">
                    <Button type="submit">Отправить</Button>
                </Group>
            </form>
        </Box>
    );
}

export default FeedbackForm;
