//import BlocklyComponent, {Block, Value, Field, Shadow} from './Blockly';
import { Grid, Text, Anchor, Group, Divider } from '@mantine/core';
import { Image, MantineProvider, Container } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import { StoreNameProvider } from '@context/StoreNameContext';
import React from 'react';

import Section from '@components/section';
import Menu from '@components/menu';
import FeedbackForm from '@components/feedbackForm';
import videoFile from '@assets/video/colob.mp4';
import rcp1 from '@assets/bg/rcp1.png';
import rcp2 from '@assets/bg/rcp2.png';
import rcp3 from '@assets/bg/rcp3.png';
import vmc850 from '@assets/bg/vmc850.png';
import videoSource from '@assets/video/rcpvideo1.mp4';
import video1 from '@assets/video/video1.mp4';
import video2 from '@assets/video/video2.mp4';
import colab2 from '@assets/video/colab2.mp4';
import imageSource from '@assets/bg/rcpimg1.jpg';
import '@mantine/carousel/styles.css';
import CustomCarousel from '@components/customCarousel';

function HomePage() {
    const slides = [
        { type: 'video', src: videoSource },
        { type: 'image', src: imageSource },
    ];

    return (
        <>
            <Section storeName="section-1" className="section-header">
                <Menu storeName="menu" />
            </Section>
            <Section storeName="section-2">
                <div class="col">
                    <h1 class="font-size-36 fw-semibold mt-5 mb-2">
                        Добро пожаловать на сайт автоматизации производства!
                    </h1>
                    <h2 class="font-size-30 fw-semibold mt-5 mb-2">
                        Роботизированные Комплексы для Вашего Бизнеса
                    </h2>
                    <p class="font-size-16 mb-5">
                        Откройте новые горизонты производительности с нашими
                        передовыми роботами и оборудованием. Узнайте, как
                        инновации могут преобразить ваше предприятие.
                    </p>
                </div>
                <div class="col">
                    <MantineProvider>
                        <Container>
                            <video
                                className="angled-video"
                                src={videoFile}
                                autoPlay
                                muted
                                loop
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    objectFit: 'cover',
                                }}
                            >
                                Ваш браузер не поддерживает воспроизведение
                                видео.
                            </video>
                        </Container>
                    </MantineProvider>
                </div>
            </Section>
            <Section storeName="section-3" id="rcp-robots">
                <div className="row">
                    <h2 class="font-size-36 fw-semibold mt-5 mb-2">
                        RCP Роботы: Технологии Завтрашнего Дня
                    </h2>
                    <div class="card-container">
                        <div class="card">
                            <img src={rcp1} alt="RCP Модель 1" />
                            <h3>RCP AR1410W</h3>
                            <p>
                                Макс. грузоподъёмность: 10 кг. Рабочая зона: до
                                1440 мм.
                            </p>
                            <a href="#" class="btn">
                                Подробнее
                            </a>
                        </div>

                        <div class="card">
                            <img src={rcp2} alt="RCP Модель 2" />
                            <h3>RCP AR1808W</h3>
                            <p>
                                Макс. грузоподъёмность: 8 кг. Рабочая зона: до
                                1840 мм.
                            </p>
                            <a href="#" class="btn">
                                Подробнее
                            </a>
                        </div>

                        <div class="card">
                            <img src={rcp3} alt="RCP Модель 3" />
                            <h3>RCP AR2006W</h3>
                            <p>
                                Макс. грузоподъёмность: 6 кг. Рабочая зона: до
                                2010 мм.
                            </p>
                            <a href="#" class="btn">
                                Подробнее
                            </a>
                        </div>
                    </div>
                </div>
            </Section>
            <Section storeName="section-4">
                <div class="col">
                    <MantineProvider>
                        <Container>
                            <video
                                className="angled-video-reverse"
                                src={videoSource}
                                autoPlay
                                muted
                                loop
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    objectFit: 'cover',
                                }}
                            >
                                Ваш браузер не поддерживает воспроизведение
                                видео.
                            </video>
                        </Container>
                    </MantineProvider>
                </div>
                <div class="col">
                    <h2 class="font-size-36 fw-semibold mt-5 mb-2">
                        В Действии: RCP Роботы
                    </h2>
                    <h3 class="font-size-30 fw-semibold mt-5 mb-2">
                        Повышение Производительности на Каждом Этапе
                    </h3>
                    <p class="font-size-16 mb-5">
                        Наши RCP роботы активно способствуют оптимизации
                        производственных процессов, обеспечивая надёжность и
                        точность на всем протяжении работы. Они уже используются
                        на множестве предприятий по всей стране, помогая
                        экономить время и ресурсы.
                    </p>
                    <p class="font-size-16 mb-5">
                        Посмотрите видео, демонстрирующее их реальную работу в
                        условиях, приближенных к вашим, и оцените результаты на
                        фото. Убедитесь сами, что современные технологии
                        способны вывести ваш бизнес на новый уровень.
                    </p>
                </div>
            </Section>
            <Section storeName="section-5">
                <div class="col">
                    <MantineProvider>
                        <Container>
                            <video
                                className="angled-video-reverse"
                                src={video1}
                                autoPlay
                                muted
                                loop
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    objectFit: 'cover',
                                }}
                            >
                                Ваш браузер не поддерживает воспроизведение
                                видео.
                            </video>
                        </Container>
                    </MantineProvider>
                </div>
                <div class="col">
                    <MantineProvider>
                        <Container>
                            <video
                                className="angled-video"
                                src={video2}
                                autoPlay
                                muted
                                loop
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    objectFit: 'cover',
                                }}
                            >
                                Ваш браузер не поддерживает воспроизведение
                                видео.
                            </video>
                        </Container>
                    </MantineProvider>
                </div>
            </Section>
            <Section storeName="section-6" className="section-dark">
                <div class="col">
                    <h2 class="font-size-36 fw-semibold mt-5 mb-2">
                        Коллаборативные Роботы: Совместная Работа с Будущим
                    </h2>
                    <p class="font-size-16 mb-5">
                        Коллаборативные роботы, или коботы, — это автономные
                        машины, разработанные для безопасной и эффективной
                        работы бок о бок с людьми. Они отличаются своей
                        способностью "обучаться", гибко перестраиваясь под
                        задачи, что видно на видео, где робот автоматически
                        адаптируется к изменениям положения детали
                    </p>
                    <h3 class="font-size-28 fw-semibold mt-5 mb-2">
                        Основные Преимущества и Применение
                    </h3>
                    <p class="font-size-16 mb-5">
                        Коботы легко интегрируются в существующие
                        производственные процессы и способны выполнять
                        разнообразные задачи: от сборки и упаковки до контроля
                        качества и логистики. Их простота в программировании
                        позволяет быстро адаптировать робота под нужды
                        производства без дополнительных затрат на
                        программирование, открывая новые возможности для бизнеса
                        любого масштаба.
                    </p>
                </div>
                <div class="col">
                    <MantineProvider>
                        <Container>
                            <video
                                className="angled-video"
                                src={colab2}
                                autoPlay
                                muted
                                loop
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    objectFit: 'cover',
                                }}
                            >
                                Ваш браузер не поддерживает воспроизведение
                                видео.
                            </video>
                        </Container>
                    </MantineProvider>
                </div>
            </Section>
            <Section storeName="section-7">
                <div class="col">
                    <MantineProvider>
                        <Container>
                            <img
                                className="angled-video-reverse-noborder"
                                src={vmc850}
                                alt="Ваш браузер не поддерживает воспроизведение видео."
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    objectFit: 'cover',
                                }}
                            />
                        </Container>
                    </MantineProvider>
                </div>
                <div class="col">
                    <h2 class="font-size-36 fw-semibold mt-5 mb-2">
                        Универсальное Оборудование для Всех Ваших Нужд
                    </h2>
                    <p class="font-size-16 mb-5">
                        В дополнение к нашим передовым роботам мы предлагаем
                        разнообразное производственное оборудование, способное
                        удовлетворить самые сложные задачи вашего предприятия.
                        Это станки и устройства, такие как токарные, фрезерные,
                        сверлильные и многое другое, которые идеально подходят
                        для различных этапов обработки материалов.
                    </p>
                    <h3 class="font-size-28 fw-semibold mt-5 mb-2">
                        Решения для Эффективного Производства
                    </h3>
                    <p class="font-size-16 mb-5">
                        Наши станки спроектированы для работы в условиях высокой
                        нагрузки, обеспечивая точность и надёжность. Благодаря
                        модульности и возможности быстрой переналадки, они
                        идеально интегрируются в текущие производственные
                        процессы. Это оборудование помогает не только повысить
                        производительность, но и сократить время на переход
                        между операциями, обеспечивая вашему бизнесу неизменно
                        высокое качество продукции.
                    </p>
                </div>
            </Section>
            <Section storeName="section-8" className="section-footer">
                <div class="col-feedback">
                    <h2 class="font-size-36 fw-semibold mt-5 mb-2">
                        Свяжитесь с нами: Заполните форму обратной связи
                    </h2>
                    <FeedbackForm />
                </div>
            </Section>
            <Section
                storeName="section-6"
                className="section-footer section-dark"
            >
                <footer>
                    <Container>
                        <Grid>
                            <Grid.Col span={4}>
                                <Text weight={500}>О компании</Text>
                                <Text size="sm">
                                    Мы являемся лидерами в области
                                    автоматизации, специализируясь на продаже и
                                    пусконаладке роботов, коллаборативных
                                    роботов и различных станков. Наша миссия —
                                    внедрение передовых технологий для повышения
                                    эффективности и качества производства.
                                </Text>
                            </Grid.Col>

                            <Grid.Col span={4}>
                                <Text weight={500}>Быстрые ссылки</Text>
                                <Group className="links">
                                    <Anchor href="/">Главная</Anchor>
                                    <Anchor href="#section-3">RCP</Anchor>
                                    <Anchor href="#section-6">
                                        Коллаборативные роботы
                                    </Anchor>
                                    <Anchor href="#section-7">
                                        Другое оборудование
                                    </Anchor>
                                </Group>
                            </Grid.Col>

                            <Grid.Col span={4}>
                                <Text weight={500}>Контакты</Text>
                                <Text size="sm">Email: info@example.com</Text>
                                <Text size="sm">Телефон: +123 456 7890</Text>
                                <Text size="sm">
                                    Адрес: ул. Пример, 123, Москва, Россия
                                </Text>
                            </Grid.Col>
                        </Grid>

                        <Divider my="sm" />

                        <Text align="center" size="sm" color="dimmed">
                            © {new Date().getFullYear()} Все права защищены.
                        </Text>
                    </Container>
                </footer>
            </Section>
        </>
    );
}

export default HomePage;
