// src/services/store/dynamicReducers.js
import { combineReducers } from '@reduxjs/toolkit';

const dynamicReducers = {};

export const addDynamicReducer = (store, key, reducer) => {
    if (!dynamicReducers[key]) {
        dynamicReducers[key] = reducer;
        store.replaceReducer(createRootReducer(store));
    }
};

const createRootReducer = (store) => {
    const staticReducers = store.staticReducers || {};
    return combineReducers({
        ...staticReducers,
        ...dynamicReducers,
    });
};

export default dynamicReducers;
