// src/components/section/Section.js
import React from 'react';
import { useSelector } from 'react-redux';
import { useStoreName } from '@context/StoreNameContext';
import withStoreName from '@context/withStoreName';
import createSectionSlice from '@slices/sectionSlice';
import { useDynamicReducer } from '@helpers/useDynamicReducer';

const Section = ({ className, children }) => {
    const storeName = useStoreName();
    const slice = useDynamicReducer(storeName, createSectionSlice);

    const test = useSelector((state) => state[storeName]?.storeName);

    return (
        <section id={storeName} className={`section ${storeName} ${className}`}>
            <div className={`section-wrap`}>
                {children ? children : 'No content available'}
            </div>
        </section>
    );
};

export default withStoreName(Section);
