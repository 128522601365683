//src\services\store\Store.js
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import notificationManagerReducer from '@services/notificationManager/notificationManagerSlice';
import settingsSlice from '@slices/settingsSlice';
import dynamicReducers, { addDynamicReducer } from './dynamicReducers';

const staticReducers = {
    notifications: notificationManagerReducer,
    settings: settingsSlice,
};

const createRootReducer = () =>
    combineReducers({
        ...staticReducers,
        ...dynamicReducers,
    });

const Store = configureStore({
    reducer: createRootReducer(),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    devTools: process.env.NODE_ENV !== 'production',
});

Store.staticReducers = staticReducers;

export default Store;
export { addDynamicReducer };
