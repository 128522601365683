// src/context/withStoreName.js
import React from 'react';
import { StoreNameProvider, useStoreName } from '@context/StoreNameContext';

const withStoreName = (Component) => {
    return (props) => {
        const parentStoreName = useStoreName(); // используем контекст родителя
        return (
            <StoreNameProvider
                storeName={props.storeName}
                parentStoreName={parentStoreName}
            >
                <Component {...props} />
            </StoreNameProvider>
        );
    };
};

export default withStoreName;
