import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    test: 'tes22t',
    theme: 'light',
};

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setTheme(state, action) {
            state.theme = action.payload;
        },
    },
});

export const { setTheme, setLanguage } = settingsSlice.actions;
export default settingsSlice.reducer;
