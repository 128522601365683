// src/slices/menuSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { setProperty } from 'helpers/setProperty';

// Функция для инициализации состояния с использованием storeName и parentStoreName
const getInitialState = (storeName, parentStoreName = null) => ({
    info: 'menuSlice',
    storeName: storeName,
    parent: parentStoreName,
    data2: [],
});

const createMenuSlice = (storeName, parentStoreName) =>
    createSlice({
        name: storeName,
        initialState: getInitialState(storeName, parentStoreName),
        reducers: {
            setStateProperty: setProperty,
        },
    });

export default createMenuSlice;
