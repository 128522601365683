import { createSlice } from '@reduxjs/toolkit';
import { setProperty } from 'helpers/setProperty';

// Функция для инициализации состояния с использованием storeName
const getInitialState = (storeName) => ({
    info: 'SectionSlice',
    color: 'default',
    storeName: storeName,
    data: [],
});

const createSectionSlice = (storeName) =>
    createSlice({
        name: storeName,
        initialState: getInitialState(storeName),
        reducers: {
            setStateProperty: setProperty,
        },
    });

export default createSectionSlice;
