// src/context/StoreNameContext.js
import React, { createContext, useContext } from 'react';

const StoreNameContext = createContext(null);
const ParentStoreNameContext = createContext(null);

export const StoreNameProvider = ({ storeName, parentStoreName, children }) => (
    <ParentStoreNameContext.Provider value={parentStoreName}>
        <StoreNameContext.Provider value={storeName}>
            {children}
        </StoreNameContext.Provider>
    </ParentStoreNameContext.Provider>
);

export const useStoreName = () => useContext(StoreNameContext);
export const useParentStoreName = () => useContext(ParentStoreNameContext);
