import { set } from 'lodash';

export const setProperty = (state, action) => {
    const payload = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];
    payload.forEach(({ key, value }) => {
        set(state, key, value);
    });
};
