//src\services\notificationManager\notificationSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';

const notificationManagerSlice = createSlice({
  name: 'notifications',
  initialState: [],
  reducers: {
    addNotification: {
      reducer: (state, action) => {
        state.push(action.payload);
      },
      prepare: (message, type) => ({
        payload: {
          id: nanoid(),
          message,
          type,
        },
      }),
    },
    removeNotification: (state, action) => {
      return state.filter(notification => notification.id !== action.payload);
    },
  },
});

export const { addNotification, removeNotification } = notificationManagerSlice.actions;
export default notificationManagerSlice.reducer;
