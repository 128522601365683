import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Carousel } from '@mantine/carousel';
import { Image } from '@mantine/core';

import Embla from 'embla-carousel';

const CustomCarousel = ({ slides, time = 5000 }) => {
    const videoRef = useRef([]);
    const [emblaApi, setEmblaApi] = useState(null);

    const handleVideoEnd = useCallback(
        (index) => {
            if (emblaApi) {
                const nextSlideIndex = (index + 1) % slides.length;
                emblaApi.scrollTo(nextSlideIndex);
            }
        },
        [emblaApi, slides.length]
    );

    useEffect(() => {
        if (!emblaApi) return;

        const handleSelect = () => {
            const currentSlideIndex = emblaApi.selectedScrollSnap();
            const currentSlide = slides[currentSlideIndex];

            if (currentSlide.type === 'video') {
                const videoElement = videoRef.current[currentSlideIndex];
                if (videoElement) {
                    videoElement.currentTime = 0; // Сбрасываем время видео
                    videoElement.play().catch(() => {
                        console.log(
                            'Видео не может быть сыграно автоматически'
                        );
                    });
                }
            }
        };

        emblaApi.on('select', handleSelect);

        const switchSlides = () => {
            const currentSlideIndex = emblaApi.selectedScrollSnap();
            emblaApi.scrollTo((currentSlideIndex + 1) % slides.length);
        };

        let interval;
        if (time > 0) {
            interval = setInterval(switchSlides, time);
        }

        return () => {
            clearInterval(interval);
            emblaApi.off('select', handleSelect);
        };
    }, [emblaApi, slides, time]);

    return (
        <Carousel
            withIndicators
            height={400}
            loop
            getEmblaApi={setEmblaApi}
            className="angled-video" // добавляем класс
        >
            {slides.map((slide, index) => (
                <Carousel.Slide key={index}>
                    {slide.type === 'video' ? (
                        <video
                            ref={(el) => (videoRef.current[index] = el)}
                            width="100%"
                            height="100%"
                            src={slide.src}
                            onEnded={() => handleVideoEnd(index)}
                            autoPlay
                            muted
                        />
                    ) : (
                        <Image
                            src={slide.src}
                            fit="cover"
                            height={400}
                            alt={`Slide ${index}`}
                        />
                    )}
                </Carousel.Slide>
            ))}
        </Carousel>
    );
};

export default CustomCarousel;
